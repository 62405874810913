// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const videos = () => import('./views/index')
const addVideo = () => import('./views/addVideo')
// start Routes
export default [

  {
    path: '/videos',
    name: 'videos',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'videos',
        meta: {
          name: '',
          parentName: '',
          userType: '',
          permission: ''
        },
        component: videos
      },
      {
        path: 'add-video',
        name: 'addVideo',
        meta: {
          name: 'addVideo',
          parentName: 'videos',
          userType: '',
          permission: ''
        },
        component: addVideo
      },
      {
        path: 'edit-video/:id',
        name: 'editVideo',
        meta: {
          name: 'editVideo',
          parentName: 'videos',
          userType: '',
          permission: ''
        },
        component: addVideo
      }
    ]
  }
]
