export default {
  home: 'home',
  aboutUs: 'about us',
  services: 'services',
  mediaCenter: 'media center',
  contactUs: 'contact us',
  searchInAllDepartments: 'search In All Departments',
  shops: 'shops',
  permissions: 'permissions',
  insurance: 'insurance',
  transport: 'transport',
  veterinarian: 'veterinarian',
  camelTrade: 'camel trade',
  events: 'events',
  footer: 'The Ebel platform is a platform specialized in providing all services related to camels in the Kingdom of Saudi Arabia, including insurance, transportation and permit services, feed stores, food and camel supplies, and veterinary services.',
  termsConditions: 'termsConditions'
}
