const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const support = () => import('./views/index')

// start Routes
export default [
  {
    path: '/support',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'support',
        meta: {
          name: 'support',
          parentName: '',
          userType: '',
          permission: '',
          role: ''
        },
        component: support
      }
    ]
  }
]
