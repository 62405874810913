
/* eslint-disable*/
import { marketPlace } from '@/axios'

export default {
  acceptMerchent(storeId, payload) {
    return marketPlace().patch(`admin/stores/${storeId}`, payload)
  },
  addMerchent(payload) {
    return marketPlace().post('admin/merchants', payload)
  },
  getMarket(id) {
    return marketPlace().get(`admin/stores/${id}`)
  },
  editMarket(id, payload) {
    return marketPlace().patch(`admin/stores/${id}`, payload)
  },
  marketStatus(id, status, payload) {
    const data = payload ? { ...payload, status } : { status }
    return marketPlace().patch(`admin/stores/${id}`, data)
  },
  getCities() {
    return marketPlace().get('cities')
  },
  getTaxID(id) {
    return marketPlace().get(`admin/taxes/${id}`)
  },
  addTax(payload) {
    return marketPlace().post('admin/taxes', payload)
  },
  editTax(id, payload) {
    return marketPlace().patch(`admin/taxes/${id}`, payload)
  },
  restoreMerchent(store_id) {
    return marketPlace().patch(`admin/stores/restore/${store_id}`)
  },
  getAllStores () {
    return marketPlace().get('stores-categories')
  }
}
