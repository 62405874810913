export default {
  IdNumber: 'id number',
  password: 'password',
  phoneNumber: 'phone',
  userName: 'user name',
  fullName: 'full name',
  email: 'email',
  otbCode: 'OTB Code',
  check: 'check',
  enter: 'enter',
  createNewAccount: ' create new account',
  createAccount: ' create account',
  codeOtb: 'code OTB',
  otbTitle: 'تم إرسال رسالة إلى رقم الجوال الخاص بكم',
  otbInfo: 'تتضمن رمز OTB',
  continueOtb: 'continue',
  create: 'create',
  enterLogin: 'login',
  dashboard: 'dashboard',
  marketplace: 'marketplace',
  veterinary: 'veterinary',
  events: 'events',
  news: 'news',
  images: 'images',
  gallery: 'gallery',
  videos: 'videos',
  video: 'video',
  transactions: 'transactions',
  payments: 'payments',
  website: 'website',
  termsCondition: 'terms and condition',
  privacyPolicy: 'privacy and policy'
}
