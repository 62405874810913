import auth from './auth/auth'
import endUser from './endUser/endUser'
import admins from './admins/admins'
import main from './main/main'
import insurance from './insurance/insurance'
import transportation from './transportation/transportation'
import veterinary from './veterinary/veterinary'
export default {
  auth,
  endUser,
  admins,
  main,
  veterinary,
  insurance,
  transportation
}
