// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const books = () => import('./views/index')
const hospitals = () => import('./views/hospitals/index.vue')
const editHospital = () => import('./views/hospitals/editHospital.vue')
const doctors = () => import('./views/doctors/index')
const addDoctor = () => import('./views/doctors/addDoctor')
const editDoctor = () => import('./views/doctors/editDoctor')
const financialOperations = () => import('./views/financialOperations')
const withdrawalRequests = () => import('./views/withdrawalRequests/index.vue')
const addWithdrawalRequests = () => import('./views/withdrawalRequests/addWithdrawal.vue')
const documents = () => import('./views/documents/index.vue')
const addDocuments = () => import('./views/documents/addDocuments.vue')
const viewDoctorDocuments = () => import('./views/viewDoctorDocuments')
const inActiveDoctors = () => import('./views/inActiveDoctors')
const inActiveClinics = () => import('./views/inActiveHospital')
const showInActiveClinics = () => import('./views/showInActiveHospital')
const ShowInActiveDoctor = () => import('./views/showInActiveDoctor')
const settings = () => import('./views/settings')

// start Routes
export default [
  {
    path: '/veterianian',
    name: 'veterianian',
    component: dashboardLayout,
    children: [
      {
        path: 'books',
        name: 'books',
        meta: {
          name: 'books',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: books
      },
      {
        path: 'hospitals',
        name: 'hospitals',
        meta: {
          name: 'hospitals',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: hospitals
      },
      {
        path: 'show-hospital/:id',
        name: 'showHospital',
        meta: {
          name: 'showHospital',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: editHospital
      },
      {
        path: 'edit-hospital/:id',
        name: 'editHospital',
        meta: {
          name: 'editHospital',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: editHospital
      },
      {
        path: 'doctors',
        name: 'doctors',
        meta: {
          name: 'doctors',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: doctors
      }, {
        path: 'add-doctor',
        name: 'addDoctor',
        meta: {
          name: 'addDoctor',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: addDoctor
      }, {
        path: 'edit-doctor/:id',
        name: 'editDoctor',
        meta: {
          name: 'editDoctor',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: editDoctor
      }, {
        path: 'show-doctor/:id',
        name: 'showDoctor',
        meta: {
          name: 'showDoctor',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: editDoctor
      }, {
        path: 'veterinaryFinancialOperations',
        name: 'veterinaryFinancialOperations',
        meta: {
          name: 'veterinaryFinancialOperations',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: financialOperations
      }, {
        path: 'withdrawal-requests',
        name: 'withdrawal-requests',
        meta: {
          name: 'withdrawal-requests',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: withdrawalRequests
      }, {
        path: 'show-withdrawal-requests/:id',
        name: 'show-withdrawal-requests',
        meta: {
          name: 'show-withdrawal-requests',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: addWithdrawalRequests
      }, {
        path: 'documents',
        name: 'documents',
        meta: {
          name: 'documents',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: documents
      }, {
        path: 'add-documents',
        name: 'addDocuments',
        meta: {
          name: 'addDocuments',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: addDocuments
      }, {
        path: 'edit-documents/:id',
        name: 'editDocuments',
        meta: {
          name: 'editDocuments',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: addDocuments
      }, {
        path: 'view-doctor-documents/:id',
        name: 'viewDoctorDocuments',
        meta: {
          name: 'viewDoctorDocuments',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: viewDoctorDocuments
      },
      {
        path: 'inactive-doctors',
        name: 'inActiveDoctors',
        meta: {
          name: 'inActiveDoctors',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: inActiveDoctors
      },
      {
        path: 'inactive-clinics',
        name: 'inActiveClinics',
        meta: {
          name: 'inActiveClinics',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: inActiveClinics
      },
      {
        path: 'show-inactive-clinics/:id',
        name: 'showInActiveClinics',
        meta: {
          name: 'showInActiveClinics',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: showInActiveClinics
      },
      {
        path: 'show-inactive-doctors/:id',
        name: 'showInActiveDoctor',
        meta: {
          name: 'showInActiveDoctor',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: ShowInActiveDoctor
      },
      {
        path: 'veteSettings',
        name: 'veteSettings',
        meta: {
          name: 'veteSettings',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: settings
      }
    ]
  }
]
