 <!-- Docs

Props:
1- Design Cell
  title => String
  icon => (class of line awesome)

2- showIf => condition must be a true to show cell

3- Routes
  ifNavigate => Boolean
  routerName => String
  routerPath => String
  routerParams => String

4- takeAction ($emit)
   takeActionParams => (get value of key in row)

5- change Status (with api)
-->
<template>
  <div>
    <div v-if="cellActions.actions.length" class="d-flex justify-content-start w-full gap_1">
      <template v-for="(i, key) in cellActions.actions">
        <span
          v-if="i.showIf ? i.showIf(dataItem) : true"
          @mouseover="activeTooltip = i"
          @mouseleave="activeTooltip = null"
          @click="fireAction(i)"
          class="tooltip-wrapper"
          :key="key"
        >
          <i :class="[i.icon, `text-${i.color}`]" />
          <!-- Tooltip appears on hover -->
          <div v-if="activeTooltip === i" class="custom-tooltip">
            {{ i.text }}
            <div class="tooltip-arrow"></div>
          </div>
        </span>
      </template>
    </div>
  </div>
</template>
<script>
import EventBus from '@/eventBus'
export default {
  props: {
    cellActions: {
      type: Object
    },
    dataItem: {
      type: Object
    }
  },
  data () {
    return {
      modalTitle: '',
      modalColor: 'danger',
      activeTooltip: null// Track which tooltip is active
    }
  },
  methods: {
    fireAction (action) {
      if (action.ifNavigate) {
        let params = {}
        let path = action.routePath || null

        const actionParams = action.routeName || null
        if (action.getParams) {
          params = action.getParams(this.dataItem)
        } else if (action.routePath) {
          const pathParams = action.routePath.split('/').filter(e => e.includes(':')).map(e => e.replace(':', ''))
          pathParams.forEach(e => {
            params[e] = this.dataItem[e]
            path = path.replace(`:${e}`, this.dataItem[e])
          })
        } else if (Array.isArray(actionParams)) {
          actionParams.forEach(e => {
            params[e] = this.dataItem[e]
          })
        } else params[actionParams || 'id'] = this.dataItem[actionParams || 'id']
        this.$router.push(
          path || {
            name: action.routeName,
            params
          }
        )
      } else if (action.actionName) {
        let objData = {}
        if (action.actionParams === 'fullObj') {
          console.log('action.actionParams => ', action.actionParams)
          objData = this.dataItem
        } else if (action.actionParams) {
          action.actionParams.forEach(params => {
            if (this.dataItem[params]) {
              objData[params] = this.dataItem[params]
            }
          })
        }
        this.$root.$emit(action.actionName, objData)
      } else if (action.showAlert) {
        EventBus.$emit('openDeleteModal', {
          actionHeader: action.actionHeader ? action.actionHeader : 'Delete',
          titleHeader: action.titleHeader ? action.titleHeader : '',
          textContent: action.textContent ? this.dataItem[action.textContent] : '',
          url: action.url ? action.url : '',
          rowId: this.dataItem.id,
          baseUrl: action.baseUrl,
          question: action.question ? action.question : `Are You Sure You Want Delete This ${action.titleHeader}?`,
          textDeleteButton: action.textDeleteButton ? action.textDeleteButton : `YES, ${action.actionHeader}`,
          textCancelButton: action.textCancelButton ? action.textCancelButton : 'NO, CANCEL',
          icon: action.iconClass ? action.iconClass : 'las la-trash-alt',
          type: action.type ? action.type : 'delete',
          actionOnAlert: action.actionOnAlert ? action.actionOnAlert : ''
        })
      }
    }
  }
}
</script>

<style>
/* Styling similar to BootstrapVue tooltip */
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  position: absolute;
  top: -35px; /* Adjusted to make space for the arrow */
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.875rem;
  line-height: 1.4;
  z-index: 1000;
  white-space: nowrap;
  opacity: 0.9; /* Adjust for desired opacity */
  transition: opacity 0.2s;
  visibility: visible;
}

/* Tooltip arrow styling */
.tooltip-arrow {
  position: absolute;
  top: 100%; /* Position the arrow below the tooltip */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* The color of the arrow, same as tooltip background */
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 0.9; /* Ensure tooltip becomes visible on hover */
}
</style>
