import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/modules/auth/auth.routes'
import dashboardRoutes from '../modules/dashboard/dashboard.routes'
import marketPlaceRoutes from '../modules/marketPlace/marketPlace.routes'
import insuranceRoutes from '../modules/insurance/insurance.routes'
import transportation from '@/modules/transportation/transportation.routes'
import veterianianRoutes from '../modules/veterianian/veterianian.routes'
import eventsRoutes from '../modules/events/events.routes'
import newsRoutes from '@/modules/news/news.routes'
import imagesRoutes from '@/modules/galleries/galleries.routes'
import videosRoutes from '@/modules/videos/videos.routes'
import transactions from '@/modules/transactions/transactions.routes'
import supportRoutes from '@/modules/support/support.routes'

/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')

Vue.use(VueRouter)

const routes = [
  ...auth,
  ...dashboardRoutes,
  ...marketPlaceRoutes,
  ...insuranceRoutes,
  ...transportation,
  ...eventsRoutes,
  ...veterianianRoutes,
  ...newsRoutes,
  ...imagesRoutes,
  ...videosRoutes,
  ...transactions,
  ...supportRoutes,
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
/* router.beforeEach((to, from, next) => {
  if (to.meta.userType === 'both') {
    next()
  } else if (securityFunctions.methods.isAdmin(to.meta.userType)) {
    if (securityFunctions.methods.hasPer(to.meta.permission)) {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  } else {
    if (securityFunctions.methods.hasServiceType(to.meta.serviceTypes) || to.meta.serviceTypes === 'all') {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  }
}) */
export default router
