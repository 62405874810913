export default {
  transportation: 'النقل',
  transportationInActive: 'السائقين الغير مفعلين',
  companyInActive: 'الشركات الغير مفعلين',
  driverRequest: 'طلب تفعيل السائق',
  companyRequest: 'طلب تفعيل الشركة',
  process: 'الرحلات',
  companies: 'الشركات',
  company: 'الشركة',
  drivers: 'السائقين',
  driver: 'السائق',
  offDrivers: 'السائقين الغير مفعلين',
  offCompanies: 'الشركات الغير مفعلة',
  vehiclesType: 'أقسام الشاحنات',
  vehiclesMake: 'أنواع الشاحنات',
  vehicles: 'الشاحنات',
  companySettings: 'إعدادات الشركة',
  driversSettings: 'إعدادات السائق',
  driver_suspension_reason: 'سبب ايقاف السائق',
  company_suspension_reason: 'سبب ايقاف الشركة',
  personal_data: 'البيانات الشخصية',
  full_name: 'الاسم الكامل',
  name: 'الاسم',
  email: 'البريد الإلكتروني',
  phone: 'الهاتف',
  id_number: 'رقم الهوية',
  ownerNationalID: 'الهوية الوطنية للمالك',
  address: 'العنوان',
  city: 'المدينة',
  description: 'الوصف',
  status: 'الحالة',
  created_at: 'تاريخ الإنشاء',
  logo: 'صورة الشعار',
  cover: 'صورة الغلاف',
  view_image: 'عرض الصورة',
  photo: 'الصورة',
  photos: 'الصور'
}
