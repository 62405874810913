// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const images = () => import('./views/index')
const addImages = () => import('./views/addImages')
// start Routes
export default [

  {
    path: '/images',
    name: 'images',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'images',
        meta: {
          name: '',
          parentName: '',
          userType: '',
          permission: ''
        },
        component: images
      },
      {
        path: 'add-images',
        name: 'addImages',
        meta: {
          name: 'addImages',
          parentName: 'images',
          userType: '',
          permission: ''
        },
        component: addImages
      },
      {
        path: 'edit-image/:id',
        name: 'editImage',
        meta: {
          name: 'editImage',
          parentName: 'images',
          userType: '',
          permission: ''
        },
        component: addImages
      }
    ]
  }
]
