// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const insurancePolicies = () => import('./views/index')

const customers = () => import('./views/customers')

const insuranceCompanies = () => import('./views/insuranceCompanies')

const settings = () => import('./views/settings')

// start Routes
export default [
  {
    path: '/insurance',
    name: 'insurance',
    component: dashboardLayout,
    children: [
      {
        path: 'insurance-policies',
        name: 'insurancePolicies',
        meta: {
          name: 'insurancePolicies',
          parentName: 'insurance',
          userType: '',
          permission: ''
        },
        component: insurancePolicies
      },
      {
        path: 'insurance-customers',
        name: 'insuranceCustomers',
        meta: {
          name: 'insuranceCustomers',
          parentName: 'insurance',
          userType: '',
          permission: ''
        },
        component: customers
      },
      {
        path: 'insurance-companies',
        name: 'insuranceCompanies',
        meta: {
          name: 'insuranceCompanies',
          parentName: 'insurance',
          userType: '',
          permission: ''
        },
        component: insuranceCompanies
      },
      {
        path: 'settings',
        name: 'settings',
        meta: {
          name: 'settings',
          parentName: 'settings',
          userType: '',
          permission: ''
        },
        component: settings
      }
    ]
  }
]
