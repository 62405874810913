// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const transactions = () => import('./views/index')
// start Routes
export default [
  {
    path: '/transactions',
    name: 'transactions',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'transactions',
        meta: {
          name: '',
          parentName: '',
          userType: '',
          permission: ''
        },
        component: transactions
      }
    ]
  }
]
