// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const news = () => import('./views/index')
const addNews = () => import('./views/addNews')
const categories = () => import('./views/categories')
const addCategory = () => import('./views/addCategory')
// start Routes
export default [

  {
    path: '/news',
    name: 'news',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'news',
        meta: {
          name: '',
          parentName: '',
          userType: '',
          permission: ''
        },
        component: news
      },
      {
        path: 'categories',
        name: 'categories',
        meta: {
          name: 'categories',
          parentName: 'news',
          userType: '',
          permission: ''
        },
        component: categories
      },
      {
        path: 'categories/add-category',
        name: 'addCategory',
        meta: {
          name: 'addCategory',
          parentName: 'categories',
          userType: '',
          permission: ''
        },
        component: addCategory
      },
      {
        path: 'edit-category/:id',
        name: 'editCategory',
        meta: {
          name: 'editCategory',
          parentName: 'news',
          userType: '',
          permission: ''
        },
        component: addCategory
      },
      {
        path: 'add-news',
        name: 'addNews',
        meta: {
          name: 'addNews',
          parentName: 'news',
          userType: '',
          permission: ''
        },
        component: addNews
      },
      {
        path: 'edit-news/:id',
        name: 'editNews',
        meta: {
          name: 'editNews',
          parentName: 'news',
          userType: '',
          permission: ''
        },
        component: addNews
      }
    ]
  }
]
