// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const events = () => import('./views/index')
const addEvent = () => import('./views/addEvent')
// start Routes
export default [

  {
    path: '/events',
    name: 'events',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'events',
        meta: {
          name: '',
          parentName: '',
          userType: '',
          permission: ''
        },
        component: events
      },
      {
        path: 'add-event',
        name: 'addEvent',
        meta: {
          name: 'addEvent',
          parentName: 'events',
          userType: '',
          permission: ''
        },
        component: addEvent
      },
      {
        path: 'edit-event/:id',
        name: 'editEvent',
        meta: {
          name: 'editEvent',
          parentName: 'events',
          userType: '',
          permission: ''
        },
        component: addEvent
      }
    ]
  }
]
